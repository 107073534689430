@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

body {
  font-family: "Merriweather Sans", sans-serif;
}
.bg-success {
  background: rgb(33 135 46 / 0.4) !important;
}
.bg-danger {
  background: rgb(195 21 21 / 0.4) !important;
}
.bg-warning {
  background: rgb(255 202 105 / 0.7) !important;
}

.row {
  align-items: center;
}

.btn {
    /*border: 2px solid rgb(228, 240, 253);*/
}

.btn-info {
  /* background-color: rgb(128, 119, 109); */
}

.detailsJumbo {
    width: 50%;
}

@media screen and (max-width: 480px) {
  .detailsJumbo {
    width: 100%;
  }
}
