@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap);
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* Provide sufficient contrast against white background */

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body {
  font-family: "Merriweather Sans", sans-serif;
}
.bg-success {
  background: rgb(33 135 46 / 0.4) !important;
}
.bg-danger {
  background: rgb(195 21 21 / 0.4) !important;
}
.bg-warning {
  background: rgb(255 202 105 / 0.7) !important;
}

.row {
  -webkit-align-items: center;
          align-items: center;
}

.btn {
    /*border: 2px solid rgb(228, 240, 253);*/
}

.btn-info {
  /* background-color: rgb(128, 119, 109); */
}

.detailsJumbo {
    width: 50%;
}

@media screen and (max-width: 480px) {
  .detailsJumbo {
    width: 100%;
  }
}

